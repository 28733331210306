import React, { useState } from "react";
import { changepassword } from "../../store/actions/AuthActions";
import { connect, useDispatch } from "react-redux";
import { Button } from "antd";

// image
const ChangePassword = (props, { history }) => {
  const [oldpass, setOldpass] = useState("");
  const [newpass, setNewpass] = useState("");
  const [confirmpass, setconfirmpass] = useState("");
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  let errorsObj = { email: "" };
  const [errors, setErrors] = useState(errorsObj);
  const dispatch = useDispatch();
  console.log(props.auth.auth.idToken);
  //const token = props.auth.idToken;
  const onSubmit = (e) => {
    e.preventDefault();
    setBtnLoading(true);
    let error = false;
    const errorObj = { ...errorsObj };
    if (newpass !== confirmpass) {
      console.log(confirmpass, oldpass);
      errorObj.email = "Password Mismatch";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    console.log(oldpass, newpass);
    dispatch(
      changepassword(oldpass, newpass, props.auth.auth.idToken, props.history)
    ).then((res) => {
      setBtnLoading(false);
    });
    // history.push("/login");
  };
  return (
    <div className="row justify-content-center h-100 align-items-center">
      <div className="col-md-6">
        <div className="authincation-content">
          <div className="row no-gutters">
            <div className="col-xl-12">
              <div className="auth-form">
                <h4 className="text-center mb-4 ">Change Password</h4>
                <form onSubmit={(e) => onSubmit(e)}>
                  <label className="text-label">Old Password *</label>
                  <div className="input-group transparent-append mb-2">
                    <input
                      required
                      type={`${showPassword1 ? "text" : "password"}`}
                      className="form-control"
                      placeholder="Type Your Password"
                      onChange={(e) => setOldpass(e.target.value)}
                    />
                    <div
                      className="input-group-text"
                      onClick={() => setShowPassword1(!showPassword1)}
                    >
                      <i
                        className={`${
                          showPassword1 ? "fa fa-eye" : "fa fa-eye-slash"
                        }`}
                      />
                    </div>
                  </div>
                  <label className="text-label">New Password *</label>
                  <div className="input-group transparent-append mb-2">
                    <input
                      required
                      type={`${showPassword2 ? "text" : "password"}`}
                      className="form-control"
                      placeholder="Type Your Password"
                      onChange={(e) => setNewpass(e.target.value)}
                    />
                    <div
                      className="input-group-text"
                      onClick={() => setShowPassword2(!showPassword2)}
                    >
                      <i
                        className={`${
                          showPassword2 ? "fa fa-eye" : "fa fa-eye-slash"
                        }`}
                      />
                    </div>
                  </div>
                  <label className="text-label">Confirm Password *</label>
                  <div className="input-group transparent-append mb-2">
                    <input
                      required
                      type={`${showPassword3 ? "text" : "password"}`}
                      className="form-control"
                      placeholder="Type Your Password"
                      onChange={(e) => setconfirmpass(e.target.value)}
                    />
                    <div
                      className="input-group-text"
                      onClick={() => setShowPassword3(!showPassword3)}
                    >
                      <i
                        className={`${
                          showPassword3 ? "fa fa-eye" : "fa fa-eye-slash"
                        }`}
                      />
                    </div>
                    <div
                      id="val-username1-error"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    >
                      {errors.email && (
                        <div className="text-danger fs-12">{errors.email}</div>
                      )}
                    </div>
                  </div>

                  <div className="text-center mt-2">
                    <Button
                      type="submit"
                      loading={btnLoading}
                      className="btn btn-primary btn-block"
                    >
                      SUBMIT
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(ChangePassword);
