/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";

import { DataGrid } from "@mui/x-data-grid";
import { connect, useDispatch } from "react-redux";
import { getHomeStatAction } from "../../../store/actions/PostActions";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { forcepassword, logout } from "../../../store/actions/AuthActions";
import { Link } from "react-router-dom";
import { Spin } from "antd";

const Home = (props) => {
  const { user, dashdata } = props;
  console.log(dashdata);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [pagesize, setpagesize] = useState(10);
  const [open1, setOpen1] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [oldpass, setOldpass] = useState("");
  const [newpass, setNewpass] = useState("");
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);

  let errorsObj = { email: "" };
  const [errors, setErrors] = useState(errorsObj);

  useEffect(() => {
    dispatch(getHomeStatAction()).then((res) => {
      setData(props.dashdata);
      console.log(props.dashdata.activation_count);
      setLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    console.log(user);
    if (user.password_changed === 0) {
      setOpen(true);
    }
  }, [user]);

  // console.log(props.dashdata.activation_count.daily);

  const onSubmit = (e) => {
    e.preventDefault();
    setBtnLoading(true);
    let error = false;
    const errorObj = { ...errorsObj };
    if (newpass !== oldpass) {
      console.log(oldpass);
      errorObj.email = "Password Mismatch";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    console.log(oldpass, newpass, user.idToken);
    dispatch(forcepassword(newpass, user.idToken))
      .then((res) => {
        console.log(res);
        setOpen(false);
        setBtnLoading(false);
        dispatch(logout(props.history));
      })
      .catch((err) => {
        console.log(err);
        setBtnLoading(false);
      });
    // history.push("/login");
  };
  const useStyles = makeStyles({
    grid: {
      fontFamily: "poppins, sans-serif !important",
    },
  });
  const classes = useStyles();
  const columns = [
    {
      field: "created_date",
      headerName: "Date & Time",
      headerClassName: "bold-text",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "message",
      headerName: "message",
      headerClassName: "bold-text",
      minWidth: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 250,
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      align: "center",
      renderCell: (cellValues) => {
        return (
          <span>
            <Link
              to="#"
              onClick={() => handleclick(cellValues)}
              className="btn btn-primary shadow btn-xs  me-1"
            >
              View Message
            </Link>
          </span>
        );
      },
    },
  ];
  const handleclick = (e) => {
    console.log(e.row);
    setData(e.row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Spin spinning={loading} style={{ zIndex: -1 }}>
        <div className="row">
          <div className="col-xl-3 col-xxl-3 col-lg-6 col-sm-6">
            <div className="widget-stat card">
              <div className="card-body  p-3">
                <div className="media">
                  <div className="media-body">
                    <h5 className="mb-3">Daily Activations</h5>
                    <h3 className="mb-3">
                      {props.dashdata?.activation_count?.daily}
                    </h3>
                  </div>
                  <span>
                    <i class="fa fa-lg fa-chart-line text-info"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-xxl-3 col-lg-6 col-sm-6">
            <div className="widget-stat card">
              <div className="card-body  p-3">
                <div className="media">
                  <div className="media-body">
                    <h5 className="mb-3">WTD Activations</h5>
                    <h3 className="mb-3">
                      {" "}
                      {props.dashdata?.activation_count?.WTD}
                    </h3>
                  </div>
                  <span>
                    <i class="fa fa-lg fa-chart-line text-info"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-xxl-3 col-lg-6 col-sm-6">
            <div className="widget-stat card">
              <div className="card-body  p-3">
                <div className="media">
                  <div className="media-body">
                    <h5 className="mb-3">MTD Activations</h5>
                    <h3 className="mb-3">
                      {" "}
                      {props.dashdata?.activation_count?.MTD}
                    </h3>
                  </div>
                  <span>
                    <i class="fa fa-lg fa-chart-line text-info"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-xxl-3 col-lg-6 col-sm-6">
            <div className="widget-stat card">
              <div className="card-body  p-3">
                <div className="media">
                  <div className="media-body">
                    <h5 className="mb-3">Last 7 Days</h5>
                    <h3 className="mb-3">
                      {props.dashdata?.activation_count?.Last_7_Days}
                    </h3>
                  </div>
                  <span>
                    <i class="fa fa-lg fa-chart-line text-info"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-xxl-3 col-lg-6 col-sm-6">
            <div className="widget-stat card">
              <div className="card-body  p-3">
                <div className="media">
                  <div className="media-body">
                    <h5 className="mb-3">Last 30 Days</h5>
                    <h3 className="mb-3">
                      {props.dashdata?.activation_count?.Last_30_Days}
                    </h3>
                  </div>
                  <span>
                    <i class="fa fa-lg fa-chart-line text-info"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <h4 class="card-title">
          <strong>Order Summary </strong>
        </h4> */}
        {/* <div className="row mt-4 align-items-stretch">
          <div className="c-dashboardInfo col-lg-4 col-md-6">
            <div className="wrap">
              <h4 className="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">
                Today's Order
              </h4>
              <span className="hind-font caption-12 c-dashboardInfo__count">
                {props.dashdata?.orders_count?.todays_orders ?? 0}
              </span>
            </div>
          </div>
          <div className="c-dashboardInfo col-lg-4 col-md-6">
            <div className="wrap">
              <h4 className="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">
                7 Days Pending Order
              </h4>
              <span className="hind-font caption-12 c-dashboardInfo__count">
                {props.dashdata?.orders_count?.last_week_pending_orders ?? 0}
              </span>
            </div>
          </div>
          <div className="c-dashboardInfo col-lg-4 col-md-6">
            <div className="wrap">
              <h4 className="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">
                Previous Pending Orders
              </h4>
              <span className="hind-font caption-12 c-dashboardInfo__count">
                {props.dashdata?.orders_count?.previous_pending_orders ?? 0}
              </span>
            </div>
          </div>
        </div> */}
        <h4 class="card-title">
          <strong> Inventory Summary</strong>
        </h4>

        <hr className="mb-5"></hr>
        <div className="row">
          <div class="col-xl-2 col-xxl-2 col-lg-6 col-sm-6">
            <div
              class="card text-white"
              style={{ borderRadius: "-2.625rem", border: "0px" }}
            >
              <div
                class="card-header card-header-stat"
                style={{ backgroundColor: "#0eacf3" }}
              >
                <h5 class="card-title text-center text-white">0-30 days</h5>
              </div>
              <div class="card-body mb-0">
                <h2 class="card-text text-center">
                  {props.dashdata?.inventory_aging?.LT15}
                </h2>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-xxl-2 col-lg-6 col-sm-6">
            <div
              class="card text-white"
              style={{ borderRadius: "-2.625rem", border: "0px" }}
            >
              <div
                class="card-header card-header-stat"
                style={{ backgroundColor: "#0eacf3" }}
              >
                <h5 class="card-title text-center text-white">31-45 days</h5>
              </div>
              <div class="card-body mb-0">
                <h2 class="card-text text-center">
                  {" "}
                  {props.dashdata?.inventory_aging?.LT30}
                </h2>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-xxl-2 col-lg-6 col-sm-6">
            <div
              class="card text-white"
              style={{ borderRadius: "-2.625rem", border: "0px" }}
            >
              <div
                class="card-header card-header-stat"
                style={{ backgroundColor: "#0eacf3" }}
              >
                <h5 class="card-title text-center text-white">46-60 days</h5>
              </div>
              <div class="card-body mb-0">
                <h2 class="card-text text-center">
                  {props.dashdata?.inventory_aging?.LT45}
                </h2>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-xxl-2 col-lg-6 col-sm-6">
            <div
              class="card text-white"
              style={{ borderRadius: "-2.625rem", border: "0px" }}
            >
              <div
                class="card-header card-header-stat"
                style={{ backgroundColor: "#0eacf3" }}
              >
                <h5 class="card-title text-center text-white">61-90 days</h5>
              </div>
              <div class="card-body mb-0">
                <h2 class="card-text text-center">
                  {props.dashdata?.inventory_aging?.LT90}
                </h2>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-xxl-2 col-lg-6 col-sm-6">
            <div
              class="card text-white"
              style={{ borderRadius: "-2.625rem", border: "0px" }}
            >
              <div
                class="card-header card-header-stat"
                style={{ backgroundColor: "#0eacf3" }}
              >
                <h5 class="card-title text-center text-white"> {">"}90 days</h5>
              </div>
              <div class="card-body mb-0">
                <h2 class="card-text text-center">
                  {" "}
                  {props.dashdata?.inventory_aging?.GT90}
                </h2>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-xxl-2 col-lg-6 col-sm-6">
            <div
              class="card text-white"
              style={{ borderRadius: "-2.625rem", border: "0px" }}
            >
              <div
                class="card-header card-header-stat"
                style={{ backgroundColor: "grey" }}
              >
                <h5 class="card-title text-center text-white">
                  Total Inventory
                </h5>
              </div>
              <div class="card-body mb-0">
                <h2 class="card-text text-center">
                  {props.dashdata?.inventory_aging?.Grand_Total}
                </h2>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                  <strong>Message History</strong>
                </h4>
              </div>
              <div className="card-body">
                <div style={{ width: "100%" }}>
                  <div style={{ display: "flex", height: "100%" }}>
                    <div style={{ flexGrow: 1 }}>
                      <DataGrid
                        sx={{
                          ".MuiDataGrid-columnSeparator": {
                            display: "none",
                          },
                        }}
                        autoHeight
                        disableColumnMenu
                        getRowId={(row) => row.id}
                        rows={props.device}
                        className={classes.grid}
                        columns={columns}
                        pageSize={pagesize}
                        rowsPerPageOptions={[pagesize]}
                        disableSelectionOnClick
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Dialog
            open={open1}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle>{data.created_date}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {data.message}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
        </div> */}
        <Dialog
          maxWidth="sm"
          fullWidth={true}
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>Change Password</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter your new password below.
            </DialogContentText>
            <form onSubmit={(e) => onSubmit(e)}>
              <label className="text-label mt-4">New Password *</label>
              <div className="input-group transparent-append mb-2">
                <input
                  required
                  type={`${showPassword1 ? "text" : "password"}`}
                  className="form-control"
                  placeholder="Type Your Password"
                  onChange={(e) => setOldpass(e.target.value)}
                />
                <div
                  className="input-group-text"
                  onClick={() => setShowPassword1(!showPassword1)}
                >
                  <i
                    className={`${
                      showPassword1 ? "fa fa-eye" : "fa fa-eye-slash"
                    }`}
                  />
                </div>
              </div>
              <label className="text-label">Confirm Password *</label>
              <div className="input-group transparent-append mb-2">
                <input
                  required
                  type={`${showPassword2 ? "text" : "password"}`}
                  className="form-control"
                  placeholder="Type Your Password"
                  onChange={(e) => setNewpass(e.target.value)}
                />
                <div
                  className="input-group-text"
                  onClick={() => setShowPassword2(!showPassword2)}
                >
                  <i
                    className={`${
                      showPassword2 ? "fa fa-eye" : "fa fa-eye-slash"
                    }`}
                  />
                </div>
                <div
                  id="val-username1-error"
                  className="invalid-feedback animated fadeInUp"
                  style={{ display: "block" }}
                >
                  {errors.email && (
                    <div className="text-danger fs-12">{errors.email}</div>
                  )}
                </div>
              </div>

              <div className="text-center mt-5">
                <Button
                  loading={btnLoading}
                  type="submit"
                  className="btn btn-primary btn-block"
                >
                  SUBMIT
                </Button>
              </div>
            </form>
          </DialogContent>
        </Dialog>
      </Spin>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.auth.auth,
    dashdata: state.posts.stats,
    device: state.devicelist.transaction,
  };
};

export default connect(mapStateToProps)(Home);
