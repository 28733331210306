import React, { useContext } from "react";

/// React router dom
import { Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
/// Dashboard
import Home from "./components/Dashboard/Home";
import Inventory from "./components/Inventory/Inventory";
import Compliance from "./components/Compliance/index";
import Carton from "./components/Pickup/CartonPickup";
import Transactionhistory from "./components/transaction/index";
import ChangePassword from "./pages/ChangePassword";
import RMAForm from "./components/RMAReport/RMAForm";
import AllRMAReport from "./components/RMAReport/AllRMAReport";
import Dashboard from "./components/Dashboard/Dashboard";
import SalesReport from "./components/SalesReport/index";
import RetailerDocuments from "./components/Documents/RetailerDocuments";
import supportpage from "./components/support/support";
import TrainingComponent from "./components/support/TrainingComponent";
import TrainingVideo from "./components/support/TrainingVideos";
import { FAQ } from "./components/support/FAQ";
/// Pages
import Error404 from "./pages/Error404";

import Setting from "./layouts/Setting";
import { ThemeContext } from "../context/ThemeContext";
import Announcement from "./components/Announcement/Announcement";

const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard", component: Home },
    { url: "summary", component: Dashboard },
    { url: "Inventory", component: Inventory },
    { url: "IMEI", component: Carton },
    { url: "Transaction", component: Transactionhistory },
    { url: "changepassword", component: ChangePassword },
    { url: "compliancedocument", component: Compliance },
    { url: "allrmareport", component: AllRMAReport },
    { url: "rmareport", component: RMAForm },
    { url: "salesreport", component: SalesReport },
    { url: "documents", component: RetailerDocuments },
    { url: "support", component: supportpage },
    { url: "announcement", component: Announcement },
    { url: "trainingdocs", component: TrainingComponent },
    { url: "trainingvideos", component: TrainingVideo },
    { url: "faq", component: FAQ },

    // pages

    { url: "*", component: Error404 },
  ];
  console.log(window.location.pathname);
  let path = window.location.pathname;

  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 350 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <Setting />
    </>
  );
};

export default Markup;
