import axios from "axios";
import { store } from "../store/store";

const axiosInstance = axios.create({
  baseURL: `https://services.excesswies.com/api/v1/agent/`,
});

axiosInstance.interceptors.request.use((config) => {
  const state = store.getState();
  const token = state.auth.auth.idToken;
  config.headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  return config;
});

export default axiosInstance;
