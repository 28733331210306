import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
  acceptDeviceAction,
  getDeviceAction,
  transferDeviceAction,
} from "../../../store/actions/DeviceActions";
import { useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import InboxIcon from "@mui/icons-material/PhoneIphone";

//import json from "../../../agentassignlist.json";
import Alert from "@mui/material/Alert";
import { getPostsAction } from "../../../store/actions/PostActions";

const RequestDatatable = (props) => {
  console.log(props);
  const [pagesize, setpagesize] = useState(10);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [personName, setPersonName] = useState([]);
  const [devices, setDevices] = useState([]);
  const [record, setrecord] = useState([]);
  const [errtxt, seterrtxt] = useState(false);
  const [imeilist, setimeilist] = useState([]);
  const [imeirecord, setimeirecord] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [TBLLoading, setTBLLoading] = useState(false);

  const dispatch = useDispatch();
  const useStyles = makeStyles({
    grid: {
      fontFamily: "poppins, sans-serif !important",
    },
  });

  const classes = useStyles();

  const handleclick = (e) => {
    console.log(e.row);
    setimeilist(e.row.imei_device);
    setimeirecord(e.row);
    setOpen1(true);
    // const obj_arr = {
    //   imei: e.row.imei_device,
    //   agent_job_id: e.row.job_id.toString(),
    // };
    // console.log(obj_arr);
    // dispatch(acceptDeviceAction(obj_arr)).then((res) => {
    //   dispatch(getDeviceAction());
    //   dispatch(getPostsAction());
    // });
  };

  const handleacceptimei = () => {
    setBtnLoading(true);
    const obj_arr = {
      imei: imeirecord.imei_device,
      agent_job_id: imeirecord.job_id.toString(),
    };
    console.log(obj_arr);
    dispatch(acceptDeviceAction(obj_arr)).then((res) => {
      setBtnLoading(false);
      dispatch(getDeviceAction());
      dispatch(getPostsAction());
      setOpen1(false);
      setimeirecord([]);
    });
  };

  const handleclick1 = (e) => {
    console.log(e.row);
    setrecord(e.row);
    setOpen(true);
    setDevices(e.row.imei_device);
    setBtnLoading(false);
  };

  const arrayUniq = (arr1, arr2) => {
    const arrays = [...arr1, ...arr2];
    return arrays.filter((a) => !arr2.includes(a));
  };

  const handlePartial = (e) => {
    setBtnLoading(true);
    console.log(personName, record);
    if (personName.length === record.imei_device.length) {
      seterrtxt(true);
    } else {
      console.log(arrayUniq(record.imei_device, personName));
      const imei = arrayUniq(record.imei_device, personName);
      var obj_arr = {
        imei: imei.toString(),
        agent_job_id: record.job_id,
        transfer_count: imei.length,
        reason: "Sample Reason",
        accept_transfer_status: 0,
        manager_id: record.manager_id,
      };
      console.log(obj_arr);

      dispatch(transferDeviceAction(obj_arr))
        .then((res) => {
          dispatch(getDeviceAction());
          dispatch(getPostsAction());
          setOpen(false);
          setPersonName([]);
          setBtnLoading(false);
        })
        .catch((err) => {
          setBtnLoading(false);
        });
    }
  };

  const handleChange = (event) => {
    seterrtxt(false);
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    console.log(personName);
  };

  const handleClose = () => {
    setOpen(false);
    seterrtxt(false);
    setPersonName([]);
  };
  const handleClose1 = () => {
    setOpen1(false);
    setimeirecord([]);
  };
  const columns = [
    {
      field: "total_device_count",
      headerName: "IMEI's",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 250,
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      align: "center",
      renderCell: (cellValues) => {
        console.log(cellValues.row.total_device_count);
        return (
          <span>
            <Button loading={btnLoading}>
              <Link
                to="#"
                onClick={() => handleclick(cellValues)}
                className="btn btn-primary shadow btn-xs  me-1"
              >
                Accept
              </Link>
            </Button>
            {cellValues.row.total_device_count !== 1 && (
              <Button loading={btnLoading}>
                <Link
                  to="#"
                  onClick={() => handleclick1(cellValues)}
                  className="btn btn-primary shadow btn-xs  me-1"
                >
                  Partial Accept
                </Link>
              </Button>
            )}
          </span>
        );
      },
    },
    {
      field: "manager_name",
      headerName: "Manager Name",
      width: 250,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "created_at",
      headerName: "Created at",
      width: 250,
      headerAlign: "center",
      align: "center",
    },
  ];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  return (
    <div style={{ height: 400, width: "100%" }}>
      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            disableColumnMenu
            getRowId={(row) => row.job_id}
            rows={props.data.devices}
            className={classes.grid}
            columns={columns}
            loading={props.loading}
            pageSize={pagesize}
            rowsPerPageOptions={[pagesize]}
            disableSelectionOnClick
          />
        </div>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Select Device IMEI Accept</DialogTitle>
        <DialogContent>
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-checkbox-label">IMEI</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={personName}
              onChange={handleChange}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) => selected.join(",")}
              MenuProps={MenuProps}
            >
              {devices.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={personName.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {personName.length === 0 && (
            <Alert severity="warning">This field is required</Alert>
          )}
          {errtxt === true && (
            <Alert severity="error">Don't Select All IMEI'S in list </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {personName.length !== 0 && (
            <Button loading={btnLoading} onClick={handlePartial}>
              Accept
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={open1}
        onClose={handleClose1}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Device IMEI List"}</DialogTitle>
        <DialogContent>
          <List>
            {imeilist.map((imei, key) => (
              <ListItem disablePadding key={key}>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary={imei} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose1}>Cancel</Button>
          <Button loading={btnLoading} onClick={handleacceptimei}>
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RequestDatatable;
