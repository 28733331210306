/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import FreshChat from "react-freshchat";
import { Link } from "react-router-dom";
import phoneImg from "../../../images/support/help_call.png";
import emailImg from "../../../images/support/email_us.png";
import docsImg from "../../../images/support/training_documents.png";
import vidImg from "../../../images/support/training_videos.png";
import faq from "../../../images/support/FAQ.png";

const SideMenuSupport = (props) => {
  return (
    <>
      <div className="container">
        <section className="section mb-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-xl-7 text-center desc">
                <h2 className="h1 mb-3">Hi! How can we help You?</h2>
                <p className="mx-lg-8"></p>
              </div>
            </div>
          </div>
        </section>
        <section className="section pt-0">
          <div className="container">
            <div className="row gy-4 justify-content-center">
              <div className="col-sm-6 col-md-6 col-lg-5">
                <div className="card card1">
                  <div className="card-body d-flex">
                    <div className="icon-lg rounded-3 text-white">
                      {/* <i className="fa fa-envelope image-size"></i> */}
                      <img
                        src={emailImg}
                        alt="call_us"
                        style={{ width: 62, height: 62 }}
                      />
                    </div>
                    <div className="ps-3 col">
                      <h4 className="h6 mb-2">
                        <a
                          className="stretched-link text-reset"
                          href="mailto:support@wiessolution.com"
                        >
                          Email Us
                        </a>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-5">
                <div className="card card1 ">
                  <div className="card-body d-flex  ">
                    <div className="icon-lg rounded-3 text-white">
                      <img
                        src={phoneImg}
                        alt="call_us"
                        style={{ width: 62, height: 72 }}
                      />
                    </div>
                    <div className="ps-3 col">
                      <h5 className="h6 mb-2">
                        <a
                          href="tel:+14322495151"
                          className="stretched-link text-reset"
                          style={{ color: "white" }}
                        >
                          Call Us : 432-249-5151
                        </a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4">
                <div className="card card1">
                  <div className="card-body d-flex ">
                    <div className="icon-lg rounded-3 text-white">
                      <img
                        src={docsImg}
                        alt="call_us"
                        style={{ width: 62, height: 67 }}
                      />
                    </div>
                    <div className="ps-3 col">
                      <h5 className="h6 mb-2 ">
                        <a
                          className="stretched-link text-reset"
                          href="/trainingdocs"
                          alt=""
                        >
                          Training Documents
                        </a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4">
                <div className="card card1">
                  <div className="card-body d-flex ">
                    <div className="icon-lg rounded-3 text-white">
                      <img
                        src={vidImg}
                        alt="call_us"
                        style={{ width: 62, height: 52 }}
                      />
                    </div>
                    <div className="ps-3 col">
                      <h5 className="h6 mb-2 ">
                        <a
                          className="stretched-link text-reset"
                          href="/trainingvideos"
                          alt=""
                        >
                          Training Videos
                        </a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              {/* FAQs */}
              <div className="col-sm-6 col-md-6 col-lg-4">
                <div className="card card1">
                  <div className="card-body d-flex">
                    <div className="icon-lg rounded-3 text-white">
                      <img
                        src={faq}
                        alt="faq"
                        style={{ width: 62, height: 72 }}
                      />
                    </div>
                    <div className="ps-3 col">
                      <h5 className="h6 mb-2 ">
                        <a
                          className="stretched-link text-reset"
                          href="/faq"
                          alt=""
                        >
                          FAQ's
                        </a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div style={{ zIndex: 9999 }}>
          <FreshChat
            token="5f89464e-8221-4b4f-b50d-26c1311f3bfd"
            host="https://infinitelinkedsolutions-team-8427c39a44bdd8816882133.freshchat.com"
            email="lavanyarao@infinitelinked.com"
            first_name="Lavanya"
            open="true"
            onInit={(widget) => {
              widget.user.setProperties({
                email: props?.auth?.name,
                first_name: props?.auth?.name,
                // last_name: "user.lastName",
                // phone: "user.phoneNumber",
              });
            }}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(SideMenuSupport);
