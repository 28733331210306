import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import {
  RmaReportAction,
  exportRmaReportAction,
  RmaDeviceStatusAction,
  GetRMAReasonsAction,
} from "../../../store/actions/DeviceActions";
import { connect, useDispatch } from "react-redux";
import Chip from "@mui/material/Chip";
import { Button, message, Input, Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const AllRMAReport = (props) => {
  const [pagesize, setpagesize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [sbtnLoading, setSBtnLoading] = useState(false);
  const [loadingEPbtn, setLoadingEPbtn] = useState(false);
  const [keyword, setkeyword] = useState("");
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedReason1, setSelectedReason1] = useState();
  const [selectedReason2, setSelectedReason2] = useState();
  const [statusList, setStatusList] = useState();
  const [rmaReasons, setRmaReasons] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(RmaDeviceStatusAction()).then((res) => {
      console.log(res);
      setStatusList(res);
    });
    fetchData({
      search_text: keyword ?? "",
      // status: selectedStatus ?? "",
      // reason: selectedReason1 ?? "",
      // reason_two: selectedReason2 ?? "",
    });
  }, []);

  const useStyles = makeStyles({
    grid: {
      fontFamily: "poppins, sans-serif !important",
    },
  });
  const classes = useStyles();

  const fetchData = (params = {}) => {
    dispatch(RmaReportAction(params))
      .then((res) => {
        console.log("rma", res.data);
        setLoading(false);
        setSBtnLoading(false);
      })
      .catch((ex) => {
        setLoading(false);
        setSBtnLoading(false);
      });
    dispatch(GetRMAReasonsAction())
      .then((res) => {
        setRmaReasons(res);
      })
      .catch((ex) => {
        console.log(`error fetching RMA reasons`, ex);
      });
  };

  const columns = [
    {
      field: "imei",
      headerName: "IMEI",
      headerClassName: "bold-text",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "manager_name",
      headerName: "manager name",
      headerClassName: "bold-text",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "office_number",
      headerName: "office number",
      headerClassName: "bold-text",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status_text",
      headerName: "status",
      width: 200,
      sortable: false,
      headerAlign: "center",
      headerClassName: "bold-text",
      align: "center",
      renderCell: (params) => {
        return (
          <span>
            <Chip label={params.row.status_text} color="primary" />
          </span>
        );
      },
    },
    {
      field: "reason_text",
      headerName: "Reason 1",
      headerClassName: "bold-text",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "reason_two_text",
      headerName: "Reason 2",
      headerClassName: "bold-text",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "notes",
      headerName: "Comments",
      headerClassName: "bold-text",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "created_date",
      headerName: "created date",
      headerClassName: "bold-text",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    // {
    //   field: "device_image_url",
    //   headerName: "Action",
    //   width: 200,
    //   sortable: false,
    //   headerAlign: "center",
    //   headerClassName: "bold-text",
    //   align: "center",
    //   renderCell: (params) => {
    //     return (
    //       <span>
    //         <button
    //           onClick={() => handleClickOpen(params)}
    //           style={{ padding: "8px", fontSize: "13px" }}
    //           className="btn btn-primary"
    //         >
    //           {" "}
    //           View Image{" "}
    //         </button>
    //       </span>
    //     );
    //   },
    // },
  ];

  // const handleClickOpen = (e) => {
  //   console.log(e.row);
  //   setdata(e.row);
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };
  const getexportbtn = () => {
    setLoading(true);
    setLoadingEPbtn(true);
    const search_key = keyword;
    console.log(search_key);
    dispatch(exportRmaReportAction(search_key))
      .then((res) => {
        console.log(res);
        setLoading(false);
        setLoadingEPbtn(false);
        if (res.download === 0) {
          message.success(res.message);
        } else {
          window.open(res.url, "_blank");
        }
      })
      .catch((err) => {
        setLoading(false);
        setLoadingEPbtn(false);
      });
  };

  const onChangeSelect = (value) => {
    setSelectedStatus(value);
  };

  const onChangeReason1 = (value) => {
    setSelectedReason1(value);
  };

  const onChangeReason2 = (value) => {
    setSelectedReason2(value);
  };

  const searchonboardingreport = () => {
    setSBtnLoading(true);
    setLoading(true);
    var search_text = keyword;
    var reason = selectedReason1;
    var reason_two = selectedReason2;
    var status = selectedStatus;

    fetchData({
      search_text: search_text,
      // status: status,
      // reason: reason,
      // reason_two: reason_two,
    });
  };

  const clearSearch = () => {
    setLoading(true);
    setkeyword("");
    setSelectedStatus("");
    setSelectedReason1("");
    setSelectedReason2("");
    fetchData({});
    // dispatch(inventoryDeviceAction())
    //   .then((res) => {
    //     console.log(res);
    //     setLoading(false);
    //   })
    //   .catch((ex) => {
    //     setLoading(false);
    //   });
  };

  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">
              <strong>RMA Status</strong>
            </h4>
            <Button
              type="primary"
              className="mr5"
              loading={loadingEPbtn}
              onClick={getexportbtn}
            >
              <UploadOutlined style={{ fontSize: "14px" }} /> Export Report
            </Button>
          </div>
          <div className="card-body">
            <div className="row mt-1 mb-2" style={{ padding: "15px" }}>
              {/* <div className="col-4">
                <label style={{ font: "caption" }}>Select Reason</label>
                <Select
                  value={selectedReason1}
                  style={{ width: "100%" }}
                  showSearch
                  placeholder="Select Reason"
                  optionFilterProp="children"
                  onChange={onChangeReason1}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={(rmaReasons || []).map((d) => ({
                    value: d.id,
                    label: d.name,
                  }))}
                ></Select>
              </div>
              <div className="col-4">
                <label style={{ font: "caption" }}>Select Reason Two </label>
                <Select
                  value={selectedReason2}
                  style={{ width: "100%" }}
                  showSearch
                  placeholder="Select Reason"
                  optionFilterProp="children"
                  onChange={onChangeReason2}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={(rmaReasons || []).map((d) => ({
                    value: d.id,
                    label: d.name,
                  }))}
                ></Select>
              </div>
              <div className="col-4">
                <label style={{ font: "caption" }}>Select Status</label>
                <Select
                  value={selectedStatus}
                  style={{ width: "100%" }}
                  showSearch
                  placeholder="Select Status"
                  optionFilterProp="children"
                  onChange={onChangeSelect}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={(statusList || []).map((d) => ({
                    value: d.id,
                    label: d.name,
                  }))}
                ></Select>
              </div> */}
              <div className="col-4 mt-2">
                <label style={{ font: "caption" }}>Search by Keyword</label>
                <Input
                  placeholder="Search by keyword"
                  value={keyword}
                  onChange={(e) => setkeyword(e.target.value)}
                  style={{ width: "100%", display: "block" }}
                />
              </div>
              <div className="col mt-4 pt-2">
                <Button
                  type="primary"
                  className="mr5"
                  loading={sbtnLoading}
                  onClick={() => searchonboardingreport()}
                >
                  Search
                </Button>
                <Button
                  style={{ marginLeft: "10px" }}
                  type="default"
                  onClick={clearSearch}
                >
                  Clear
                </Button>
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <div style={{ display: "flex", height: "100%" }}>
                <div style={{ flexGrow: 1 }}>
                  <DataGrid
                    sx={{
                      ".MuiDataGrid-columnSeparator": {
                        display: "none",
                      },
                    }}
                    autoHeight={true}
                    loading={loading}
                    disableColumnMenu
                    getRowId={(row) => row.id}
                    rows={props.device}
                    className={classes.grid}
                    columns={columns}
                    pageSize={pagesize}
                    rowsPerPageOptions={[pagesize]}
                    disableSelectionOnClick
                    // checkboxSelection
                    // onSelectionModelChange={(ids) => {
                    //   const selectedIDs = new Set(ids);
                    //   const selectedRows = props.device.filter((row) =>
                    //     selectedIDs.has(row.imei)
                    //   );
                    //   onRowsSelectionHandler(ids, selectedRows);
                    //   console.log(ids, selectedRows);
                    // }}
                    // onSelectionModelChange={(ids) =>
                    //   onRowsSelectionHandler(ids)
                    // }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          IMEI - {data.imei} / Date - {data.created_date}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <img className="img-fluid" src={data.device_image_url} alt=" " />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    device: state.devicelist.rmareport,
  };
};

export default connect(mapStateToProps)(AllRMAReport);
