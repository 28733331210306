import {
  formatError,
  swalalert,
  login,
  saveTokenInLocalStorage,
  signUp,
  runLogoutTimer,
  forgotpasswordaction,
  changepasswordaction,
  forcepasswordaction,
} from "../../services/AuthService";

export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";

export function signupAction(email, password, history) {
  return (dispatch) => {
    signUp(email, password)
      .then((response) => {
        saveTokenInLocalStorage(response.data);
        // runLogoutTimer(dispatch, response.data.expiresIn * 1000, history);
        dispatch(confirmedSignupAction(response.data));
        history.push("/dashboard");
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(signupFailedAction(errorMessage));
      });
  };
}

export function logout(history) {
  localStorage.removeItem("userDetails");
  history.push("/login");
  return {
    type: LOGOUT_ACTION,
  };
}

export function forgotpassword(email, history) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      forgotpasswordaction(email)
        .then((response) => {
          swalalert(response.data.message, true);
          history.push("/login");
        })
        .catch((error) => {
          console.log(error.response.data.error);
          swalalert(error.response.data.error, false);
          //dispatch(signupFailedAction(errorMessage));
        });
    });
  };
}

export function changepassword(oldpass, newpass, token, history) {
  return (dispatch) => {
    console.log(history);
    changepasswordaction(oldpass, newpass, token)
      .then((response) => {
        swalalert(response.data.message, true);
        logout(history);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error.response.data.error);
        swalalert(error.response.data.error, false);
        dispatch(signupFailedAction(error));
      });
  };
}

export function forcepassword(password, token) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      forcepasswordaction(password, token)
        .then((response) => {
          console.log(response);
          resolve(response);
          swalalert(response.data.message, true);
        })
        .catch((error) => {
          console.log(error.response.data);
          reject(error);
          swalalert(error.response.data.error, false);
          // dispatch(signupFailedAction(error));
        });
    });
  };
}

export function loginAction(email, password, history) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      login(email, password)
        .then((response) => {
          console.log(response);
          const obj_arr = {
            displayName: response.data.items.name,
            email: "",
            expiresIn: response.data.items.expires_in,
            idToken: response.data.items.token,
            refreshToken: response.data.items.token,
            localId: "",
            password_changed: response.data.items.password_changed,
            role_id: response.data.items.role_id,
            user_id: response.data.items.user_id,
            office_number: response.data.items.office_number,
          };
          // console.log(response.data , obj_arr);
          resolve(obj_arr);
          saveTokenInLocalStorage(obj_arr);
          runLogoutTimer(dispatch, 1000000 * 1000, history);
          dispatch(loginConfirmedAction(obj_arr));
          history.push("/");
        })
        .catch((error) => {
          console.log(error.response.data.error);
          reject(error.response.data.error);
          const errorMessage = formatError(error.response.data.error);
          dispatch(loginFailedAction(errorMessage));
        });
    });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
